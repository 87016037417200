<template>
	<v-card>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<v-dialog v-model="dialog" max-width="575" scrollable>
			<v-card>
				<v-card-title class="pr-10">Confirmar cancelamento de seguro</v-card-title>
				<v-divider />
				<v-card-text class="black--text pt-5">
					<v-row no-gutters>
						<v-spacer />
						<v-col cols="auto">
							<v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
						</v-col>
						<v-spacer />
					</v-row>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="dialog = false"
					>Cancelar</v-btn>
					<v-btn :loading="carregando" color="primary" elevation="0" @click="cancelarSeguro()">Confirmar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-card-title>
			<v-row no-gutters class="mb-n8">
				<v-col class="d-none d-md-block pr-1">
					<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtinicio" label="Data inicial" />
				</v-col>
				<v-col class="d-none d-md-block px-1">
					<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtfim" label="Data final" />
				</v-col>
				<v-col v-if="permissao('seguro_listar_todos')" class="d-none d-md-block px-1">
					<v-autocomplete
						:items="filiaisFiltro"
						clearable
						item-text="filial"
						item-value="idfilial"
						label="Filial"
						outlined
						dense
						:disabled="carregando"
						v-model="busca.idfilial"
					></v-autocomplete>
				</v-col>
				<v-col class="d-none d-md-block px-1">
					<v-select
						clearable
						:items="[{idstatus: 'T', status: 'Cancelado'},{idstatus: 'F', status: 'OK'}]"
						item-text="status"
						item-value="idstatus"
						label="Status"
						outlined
						dense
						v-model="busca.idstatus"
					></v-select>
				</v-col>
				<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
					<v-sheet class="text-center" height="100%">
						<v-row class="pa-3">
							<v-col v-if="permissao('seguro_listar_todos')" cols="6" class="pr-3 pr-md-0 mb-n8">
								<v-autocomplete
									:items="filiaisFiltro"
									clearable
									item-text="filial"
									item-value="idfilial"
									label="Filial"
									outlined
									dense
									:disabled="carregando"
									v-model="busca.idfilial"
								></v-autocomplete>
							</v-col>
							<v-col :cols="permissao('seguro_listar_todos') ? 6 : 12" class="pr-3 pr-md-0 mb-n8">
								<v-select
									clearable
									:items="[{idstatus: 'T', status: 'Cancelado'},{idstatus: 'F', status: 'OK'}]"
									item-text="status"
									item-value="idstatus"
									label="Status"
									outlined
									dense
									v-model="busca.idstatus"
								></v-select>
							</v-col>
							<v-col cols="6">
								<InputDatePicker
									v-model="busca.dtinicio"
									label="Data inicial"
									:outlined="true"
									:dense="true"
								/>
							</v-col>
							<v-col cols="6">
								<InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true" :dense="true" />
							</v-col>
							<v-col cols="6">
								<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
							</v-col>
							<v-col cols="6">
								<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Filtrar</v-btn>
							</v-col>
						</v-row>
					</v-sheet>
				</v-bottom-sheet>
				<v-col class="pl-1">
					<v-text-field
						class="d-flex d-md-none"
						v-model="busca.busca"
						label="Buscar"
						outlined
						dense
						append-outer-icon="mdi-menu"
						@click:append-outer="sheet = !sheet"
					/>
					<v-text-field class="d-none d-md-block" v-model="busca.busca" label="Buscar" outlined dense />
				</v-col>
				<v-col cols="auto" class="ml-2">
					<v-btn
						:disabled="carregando"
						class="mt-1"
						color="primary"
						elevation="0"
						fab
						x-small
						@click="listar(0)"
					>
						<v-icon>mdi-magnify</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-card-title>
		<v-divider />
		<v-progress-linear indeterminate absolute :active="carregando" />
		<v-card-text>
			<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
			<v-simple-table v-else>
				<template v-slot:default>
					<thead>
						<tr>
							<th class="text-center">Bilhete</th>
							<th class="text-center">Cód. Cliente</th>
							<th class="text-left">Cliente</th>
							<th class="text-left">Cód. Interno</th>
							<th class="text-left">Filial</th>
							<th class="text-center">Cód. Vendedor</th>
							<th class="text-left">Vendedor</th>
							<th class="text-center">Data</th>
							<th class="text-center">Status</th>
							<th v-if="permissao('seguro_cancelar')"></th>
						</tr>
					</thead>
					<tbody>
						<template v-if="dados.total > 0">
							<tr v-for="(u, i) in dados.lista" :key="i">
								<td class="text-center">{{u.bilhete}}</td>
								<td class="text-center">{{u.idcliente}}</td>
								<td>{{u.cliente}}</td>
								<td>{{u.codbilhete}}</td>
								<td class="text-truncate">{{u.idfilial}} - {{u.filial}}</td>
								<td class="text-center">{{u.idvendedor}}</td>
								<td>{{u.vendedor}}</td>
								<td class="text-center">{{u.dtini | formataData}}</td>
								<td class="text-center">
									<template v-if="u.status == 'F'">OK</template>
									<template v-else>Cancelado</template>
								</td>
								<td class="text-truncate">
									<v-btn
										:disabled="u.status == 'T'"
										class="mr-1"
										elevation="0"
										fab
										color="primary"
										x-small
										@click="reimprimir(u)"
									>
										<v-icon>mdi-printer-outline</v-icon>
									</v-btn>
									<v-btn
										v-if="permissao('seguro_cancelar')"
										:disabled="u.status == 'T'"
										class="mr-1"
										elevation="0"
										fab
										color="error"
										x-small
										@click="dialog = true; seguro = u"
									>
										<v-icon>mdi-close</v-icon>
									</v-btn>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td class="text-center" colspan="9">Nenhum registro encontrado.</td>
							</tr>
						</template>
					</tbody>
				</template>
			</v-simple-table>
			<Paginacao
				:total="dados.total"
				@atualizar="listar"
				:carregando="carregando"
				:pg="pgSelecionada"
			/>
		</v-card-text>
		<v-progress-linear indeterminate absolute :active="carregando" />
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import CardDialog from "../Widgets/CardDialog.vue";
import InputDatePicker from "../Widgets/InputDatePicker";
import Paginacao from "../Widgets/Paginacao.vue";
import mixinFilial from "../../util/mixinFilial";
import axios from "axios";
import jsPDF from "jspdf";

export default {
	name: "SeguroLista",
	mixins: [mixinFilial],
	components: { InputDatePicker, Paginacao, CardDialog },
	data: () => ({
		sheet: false,
		carregando: false,
		carregandoSkeleton: true,
		dialog: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		dados: {},
		seguro: {},
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			idfilial: null,
			idstatus: null,
		},
		pgSelecionada: 0,
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			if (this.filiais.lista) {
				return this.filiais.lista.filter((v) => {
					return (
						v.idfilial != 6 &&
						v.idfilial != 12 &&
						v.idfilial != 25 &&
						v.idfilial != 29 &&
						v.idfilial != 33 &&
						v.idfilial != 34 &&
						v.idfilial != 37
					);
				});
			} else {
				return [];
			}
		},
	},
	methods: {
		listar(pg) {
			this.pgSelecionada = pg;
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}seguro/listar`, {
					limit: this.pgLimit,
					offset: this.pgLimit * pg,
					busca: this.busca.busca || null,
					dtini: this.busca.dtinicio || null,
					dtfim: this.busca.dtfim || null,
					idfilial: this.busca.idfilial || null,
					status: this.busca.idstatus || null,
				})
				.then((res) => {
					this.dados = res.data;
					this.carregando = false;
					this.carregandoSkeleton = false;
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		cancelarSeguro() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}seguro/cancelar`, {
					cpf: `${this.seguro.cpf}`,
					bilhete: parseInt(this.seguro.bilhete),
					nrcer: parseInt(this.seguro.nrcer),
				})
				.then((res) => {
					this.dialog = false;
					this.carregando = false;
					this.listar(0);
					if (res.data) {
						this.seguro.dtnascimento = this.seguro.dtnascimento
							.split("T")[0]
							.split("-")
							.reverse()
							.join("/");
						this.seguro.dtmovimento = this.seguro.dtmovimento
							.split("T")[0]
							.split("-")
							.reverse()
							.join("/");
						this.seguro.dtini = this.seguro.dtini
							.split("T")[0]
							.split("-")
							.reverse()
							.join("/");
						this.seguro.dtfim = this.seguro.dtfim
							.split("T")[0]
							.split("-")
							.reverse()
							.join("/");
						this.seguro.cpf =
							this.seguro.cpf.substr(0, 3) +
							"." +
							this.seguro.cpf.substr(3, 3) +
							"." +
							this.seguro.cpf.substr(6, 3) +
							"-" +
							this.seguro.cpf.substr(9, 2);
						let htmlContent;
						htmlContent = `<html>
						<head>
							<meta http-equiv="Content-Type"
								content="text/html; charset=utf-8" />
							<meta http-equiv="Content-Style-Type"
								content="text/css" />
							<meta name="generator"
								content="Aspose.Words for .NET 17.1.0.0" />
						</head>

						<body>
							<div style="padding:15pt !important;">
								<table cellspacing="0"
									cellpadding="0"
									style="width:426pt; border-collapse:collapse; ">
									<thead>
										<tr>
											<td colspan="19"
												style="border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-top-style:solid; border-top-width:0.75pt; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
												<p style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
													<span style="font-family:Arial; font-weight:bold">
														TERMO DE CANCELAMENTO MICROSSEGURO DE ACIDENTES PESSOAIS
													</span>
												</p>
											</td>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td colspan="19"
												style="border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
												<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:3pt">
													<span style="font-family:Arial; font-weight:bold">&#xa0;</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
												<p style="margin-top:10pt; margin-bottom:10pt; text-align:left; font-size:12pt;">
													<span style="font-family:Arial; font-weight:bold; color:#0f243e">
														Dados do Segurado</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:10pt; margin-bottom:0pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Nome: ${this.seguro.cliente}
													</span>
													<span style="font-family:Arial; color:#0f243e; margin-left:30pt;">
														Data Nascimento: ${this.seguro.dtnascimento}
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:5pt; margin-bottom:0pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Endereço: ${this.seguro.endereco}
													</span>
													<span style="font-family:Arial; color:#0f243e; margin-left:30pt;">
														Bairro: ${this.seguro.bairro}
													</span>
													<span style="font-family:Arial; color:#0f243e; margin-left:30pt;">
														Nº ${this.seguro.numero}
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:5pt; margin-bottom:0pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Cidade: ${this.seguro.cidade}
													</span>
													<span style="font-family:Arial; color:#0f243e; margin-left:30pt;">
														UF: ${this.seguro.uf}
													</span>
													<span style="font-family:Arial; color:#0f243e; margin-left:30pt;">
														CEP: ${this.seguro.cep}
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-bottom-style:solid; border-bottom-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:5pt; margin-bottom:10pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Tel.: ${this.seguro.telefone}
													</span>
													<span style="font-family:Arial; color:#0f243e; margin-left:30pt; ">
														CPF: ${this.seguro.cpf}
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
												<p style="margin-top:10pt; margin-bottom:10pt; text-align:left; font-size:12pt;">
													<span style="font-family:Arial; font-weight:bold; color:#0f243e">
														Dados do Bilhete de Microsseguro</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:10pt; margin-bottom:0pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Número do Bilhete:
														${this.seguro.bilhete}
														Loja: 01 - Matriz, Data Emissão:
														${this.seguro.dtini}
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:5pt; margin-bottom:0pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Início de Vigência: 24 horas do dia
														${this.seguro.dtini}
														Final de Vigência: 24 horas do dia
														${this.seguro.dtfim}
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:5pt; margin-bottom:0pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Prêmio Total (com IOF): R$ 99,00
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-bottom-style:solid; border-bottom-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:5pt; margin-bottom:10pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Data da solicitação do cancelamento: Valor total de Restituição: R$ 99,00
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
												<p style="margin-top:10pt; margin-bottom:10pt; text-align:left; font-size:12pt;">
													<span style="font-family:Arial; font-weight:bold; color:#0f243e">
														Pedido de Cancelamento</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-bottom-style:solid; border-bottom-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:5pt; margin-bottom:10pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Eu, portador do CPF acima mencionado, solicito o cancelamento do meu Microsseguro de
														Acidentes Pessoais pelo motivo:__________________________________________
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
												<p style="margin-top:10pt; margin-bottom:10pt; text-align:left; font-size:12pt;">
													<span style="font-family:Arial; font-weight:bold; color:#0f243e">
														Recibo de Quitação Recíproca e Outras Avenças</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:10pt; margin-bottom:10pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Neste ato, declaro ter recebido da Alfa Previdência e Vida S.A., o valor acima
														descrito em razão deste cancelamento.
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:10pt; margin-bottom:10pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Desta forma as partes se dão plena, mútua, rasa, geral, irretratável e irrevogável
														quitação, de todos os direitos e deveres decorrentes do período em que vigorou o
														Microsseguro de Acidentes Pessoais, declarando nada mais terem a reclamar uma da
														outra, a qualquer título, seja em juízo ou fora dele, inclusive qualquer tipo de
														indenização.
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:10pt; margin-bottom:10pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														${this.seguro.filial}, 
														${this.seguro.dtmovimento.split("/")[0]} de 
														${this.mes(this.seguro.dtmovimento.split("/")[1])}  de 
														${this.seguro.dtmovimento.split("/")[2]}.
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:10pt; margin-bottom:5pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														_______________________________ De Acordo: _______________________________
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:0pt; margin-bottom:5pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Milca Pereira Zambrini / Celso Paiva
													</span>
													<span style="font-family:Arial; color:#0f243e; margin-left: 70pt;">
														Assinatura do Segurado
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:0pt; margin-bottom:5pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Diretores
													</span>
												</p>
											</td>
										</tr>
										<tr>
											<td colspan="19"
												style="border-bottom-style:solid; border-bottom-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
												<p style="margin-top:0pt; margin-bottom:15pt; text-align:left; font-size:10pt;">
													<span style="font-family:Arial; color:#0f243e">
														Alfa Previdência e Vida S.A.
													</span>
												</p>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</body>
					</html>`;
						let doc = new jsPDF("p", "pt", "a4");
						let data = new Date();
						doc.html(htmlContent, {
							callback: function (pdf) {
								pdf.save(
									`cancelamento_${
										data.toISOString().split(".")[0]
									}.pdf`
								);
							},
						});
						doc.autoPrint();
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg =
							"Não foi possível realizar o envio do contrato!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.dialog = false;
					this.carregando = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						this.erroFormatar(e) ||
						"Não foi possível realizar o envio do contrato!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		reimprimir(seguro) {
			console.log(seguro);
			this.carregando = true;
			seguro.dtnascimento = seguro.dtnascimento
				.split("T")[0]
				.split("-")
				.reverse()
				.join("/");
			let htmlContent;
			htmlContent = `<html>
                            <head>
                                <meta http-equiv="Content-Type"
                                    content="text/html; charset=utf-8" />
                                <meta http-equiv="Content-Style-Type"
                                    content="text/css" />
                                <meta name="generator"
                                    content="Aspose.Words for .NET 17.1.0.0" />
                            </head>
                            <body>
                                <div style="padding:15pt !important;">
                                <table cellspacing="0"
                                        cellpadding="0"
                                        style="width:420pt; border-collapse:collapse; ">
                                        <thead>
                                            <tr>
                                                <td colspan="19"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; background-color:#0f243e">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:10pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#ffffff">BILHETE DE SEGURO
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colspan="19"
                                                    style="border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:3pt">
                                                        <span style="font-family:Arial; font-weight:bold">&#xa0;</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"
                                                    style="border-left-style:solid; border-left-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:left; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">BILHETE:</span>
                                                    </p>
                                                </td>
                                                <td colspan="3"
                                                    style="border-bottom:0.75pt solid #d9d9d9; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:left; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">${
															seguro.codbilhete
														}</span>
                                                    </p>
                                                </td>
                                                <td colspan="2"
                                                    style="padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:left; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">RAMO: </span>
                                                    </p>
                                                </td>
                                                <td colspan="7"
                                                    style="border-bottom:0.75pt solid #d9d9d9; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:left; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">1601 – MICROSSEGURO
                                                            PESSOAS</span>
                                                    </p>
                                                </td>
                                                <td colspan="3"
                                                    style="padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:left; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">DATA DE EMISSÃO:
                                                        </span>
                                                    </p>
                                                </td>
                                                <td colspan="2"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-bottom:0.75pt solid #d9d9d9; padding-right:5.03pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:left; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">${seguro.dtini
															.split("T")[0]
															.split("-")
															.reverse()
															.join("/")}</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="19"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:6pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">°</span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e"> SEGURADO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="15"
                                                    style="border-left-style:solid; border-left-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">NOME </span>
                                                    </p>
                                                </td>
                                                <td colspan="4"
                                                    style="border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">CPF</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="15"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.cliente
														}</span>
                                                    </p>
                                                </td>
                                                <td colspan="4"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.cpf
														}</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"
                                                    style="border-top:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">DATA DE
                                                            NASCIMENTO</span>
                                                    </p>
                                                </td>
                                                <td colspan="1"
                                                    style="border-top:0.75pt solid #bfbfbf; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">SEXO</span>
                                                    </p>
                                                </td>
                                                <td colspan="5"
                                                    style="border-top:0.75pt solid #bfbfbf; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">ESTADO CIVIL</span>
                                                    </p>
                                                </td>
                                                <td colspan="11"
                                                    style="border-top:0.75pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">PROFISSÃO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.dtnascimento
														}</span>
                                                    </p>
                                                </td>
                                                <td colspan="1"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.sexo
														}</span>
                                                    </p>
                                                </td>
                                                <td colspan="5"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.estadocivil
														}</span>
                                                    </p>
                                                </td>
                                                <td colspan="11"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.profissao
														}</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="11"
                                                    style="border-top:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">ENDEREÇO</span>
                                                    </p>
                                                </td>
                                                <td colspan="1"
                                                    style="border-top:0.75pt solid #bfbfbf; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Nº</span>
                                                    </p>
                                                </td>
                                                <td colspan="7"
                                                    style="border-top:0.75pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">COMPLEMENTO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="11"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.endereco
														}</span>
                                                    </p>
                                                </td>
                                                <td
                                                    style="border-right:0.75pt solid #bfbfbf; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.numero
														}</span>
                                                    </p>
                                                </td>
                                                <td colspan="7"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.complemento
														}</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="5"
                                                    style="border-top:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">BAIRRO</span>
                                                    </p>
                                                </td>
                                                <td colspan="4"
                                                    style="border-top:0.75pt solid #bfbfbf; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">CEP</span>
                                                    </p>
                                                </td>
                                                <td colspan="9"
                                                    style="border-top:0.75pt solid #bfbfbf; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">CIDADE</span>
                                                    </p>
                                                </td>
                                                <td
                                                    style="border-top:0.75pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">UF</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="5"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.bairro
														}</span>
                                                    </p>
                                                </td>
                                                <td colspan="4"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.cep
														}</span>
                                                    </p>
                                                </td>
                                                <td colspan="9"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.cidade
														}</span>
                                                    </p>
                                                </td>
                                                <td
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.uf
														}</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"
                                                    style="border-top:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">TELEFONE
                                                            RESIDENCIAL</span>
                                                    </p>
                                                </td>
                                                <td colspan="5"
                                                    style="border-top:0.75pt solid #bfbfbf; padding-right:5.4pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">CELULAR</span>
                                                    </p>
                                                </td>
                                                <td colspan="12"
                                                    style="border-top:0.75pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">E-MAIL</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="2"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.telefone
														}</span>
                                                    </p>
                                                </td>
                                                <td colspan="5"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.telefone
														}</span>
                                                    </p>
                                                </td>
                                                <td colspan="12"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">${
															seguro.email
														}</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="19"
                                                    style="border-top:0.75pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:6pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">°</span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e"> VIGÊNCIA DO
                                                            SEGURO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6"
                                                    style="border-left-style:solid; border-left-width:0.75pt; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">INÍCIO DE
                                                            VIGÊNCIA DO SEGURO</span>
                                                    </p>
                                                </td>
                                                <td colspan="13"
                                                    style="border-right-style:solid; border-right-width:0.75pt; padding-right:5.03pt; padding-left:5.4pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">FIM DE
                                                            VIGÊNCIA DO SEGURO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6"
                                                    style="border-right:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">Às 24 horas do dia ${seguro.dtini
															.split("T")[0]
															.split("-")
															.reverse()
															.join("/")}</span>
                                                    </p>
                                                </td>
                                                <td colspan="13"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">Às 24 horas do dia ${seguro.dtfim
															.split("T")[0]
															.split("-")
															.reverse()
															.join("/")}</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="19"
                                                    style="border-top:0.75pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:6pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">°</span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">COBERTURAS</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6"
                                                    style="border-left-style:solid; border-left-width:0.75pt; border-bottom:0.75pt solid #bfbfbf; padding-right:5.4pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">COBERTURAS</span>
                                                    </p>
                                                </td>
                                                <td colspan="4"
                                                    style="border-bottom:0.75pt solid #bfbfbf; padding-right:5.4pt; padding-left:5.4pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">FRANQUIA</span>
                                                        <span
                                                            style="font-family:Arial; font-size:6.67pt; font-weight:bold; vertical-align:super; color:#0f243e">1</span>
                                                    </p>
                                                </td>
                                                <td colspan="3"
                                                    style="border-bottom:0.75pt solid #bfbfbf; padding-right:5.4pt; padding-left:5.4pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">CARÊNCIA</span>
                                                        <span
                                                            style="font-family:Arial; font-size:6.67pt; font-weight:bold; vertical-align:super; color:#0f243e">2</span>
                                                    </p>
                                                </td>
                                                <td colspan="4"
                                                    style="border-bottom:0.75pt solid #bfbfbf; padding-right:5.4pt; padding-left:5.4pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">CAPITAL
                                                            SEGURADO</span>
                                                        <span
                                                            style="font-family:Arial; font-size:6.67pt; font-weight:bold; vertical-align:super; color:#0f243e">3</span>
                                                    </p>
                                                </td>
                                                <td colspan="2"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.4pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">PRÊMIO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6"
                                                    style="border-top:0.75pt solid #bfbfbf; border-right:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; border-bottom:1pt solid #d9d9d9; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">MORTE ACIDENTAL
                                                            (MA)</span>
                                                    </p>
                                                </td>
                                                <td colspan="4"
                                                    style="border:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Não há</span>
                                                    </p>
                                                </td>
                                                <td colspan="3"
                                                    style="border:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Não há</span>
                                                    </p>
                                                </td>
                                                <td colspan="4"
                                                    style="border:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">R$ 30.000,00</span>
                                                    </p>
                                                </td>
                                                <td colspan="2"
                                                    style="border-top:0.75pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">R$ 50,36</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="6"
                                                    style="border-top:0.75pt solid #bfbfbf; border-right:0.75pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; border-bottom:1pt solid #d9d9d9; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">MORTE ASSISTENCIA
                                                            FUNERAL - TITULAR</span>
                                                    </p>
                                                </td>
                                                <td colspan="4"
                                                    style="border:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Não há</span>
                                                    </p>
                                                </td>
                                                <td colspan="3"
                                                    style="border:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">90 DIAS</span>
                                                    </p>
                                                </td>
                                                <td colspan="4"
                                                    style="border:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">R$ 4.000,00</span>
                                                    </p>
                                                </td>
                                                <td colspan="2"
                                                    style="border-top:0.75pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; border-left:0.75pt solid #bfbfbf; border-bottom:0.75pt solid #bfbfbf; padding-right:5.03pt; padding-left:5.03pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">R$ 48,64</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="17"
                                                    style="border-top:1pt solid #d9d9d9; border-right:1pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; padding-right:4.9pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:right; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">PRÊMIO LÍQUIDO TOTAL
                                                            ANUAL</span>
                                                    </p>
                                                </td>
                                                <td colspan="2"
                                                    style="border-top:1pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; border-left:1pt solid #bfbfbf; border-bottom:1pt solid #bfbfbf; padding-right:5.03pt; padding-left:4.9pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">R$ 98,63</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="17"
                                                    style="border-right:1pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; padding-right:4.9pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:right; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">IOF</span>
                                                    </p>
                                                </td>
                                                <td colspan="2"
                                                    style="border-top:1pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; border-left:1pt solid #bfbfbf; border-bottom:1pt solid #bfbfbf; padding-right:5.03pt; padding-left:4.9pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">R$ 0,37</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="17"
                                                    style="border-right:1pt solid #bfbfbf; border-left-style:solid; border-left-width:0.75pt; padding-right:4.9pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:right; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">PRÊMIO TOTAL </span>
                                                    </p>
                                                </td>
                                                <td colspan="2"
                                                    style="border-top:1pt solid #bfbfbf; border-right-style:solid; border-right-width:0.75pt; border-left:1pt solid #bfbfbf; border-bottom:1pt solid #bfbfbf; padding-right:5.03pt; padding-left:4.9pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            R$ ${parseFloat(
																99
															).toFixed(2)}</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="19"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:6pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">OBSERVAÇÕES:</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="19"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span
                                                            style="font-family:Arial; font-size:6.67pt; font-weight:bold; vertical-align:super; color:#0f243e">1
                                                        </span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e"> FRANQUIA – Período
                                                            durante o qual o Segurado é responsável pelos prejuízos decorrentes de um sinistro.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span
                                                            style="font-family:Arial; font-size:6.67pt; font-weight:bold; vertical-align:super; color:#0f243e">2
                                                        </span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">CARÊNCIA –
                                                            Período de tempo entre a data do início de vigência do seguro e a data de entrada em
                                                            vigor da cobertura. É o espaço de tempo durante o qual o Segurado e/ou seus
                                                            beneficiários, mesmo com o pagamento dos prêmios, não têm direito a determinadas
                                                            garantias.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span
                                                            style="font-family:Arial; font-size:6.67pt; font-weight:bold; vertical-align:super; color:#0f243e">3
                                                        </span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e"> CAPITAL SEGURADO –
                                                            Valor máximo para a cobertura contratada a ser pago pela Seguradora na ocorrência do
                                                            sinistro.
                                                        </span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="19"
                                                    style="border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">BENEFICIÁRIOS:
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">A QUALQUER MOMENTO O SEGURADO
                                                            PODERÁ INDICAR BENEFICIÁRIOS, MEDIANTE SOLICITAÇÃO FORMAL, DATADA, ASSINADA E
                                                            PROTOCOLADA JUNTO AO REPRESENTANTE DE SEGUROS, QUE PROVIDENCIARÁ O ENCAMINHAMENTO
                                                            PARA A SEGURADORA. NÃO SERÃO ACEITOS INDICAÇÕES POR MEIOS REMOTOS. SERÁ CONSIDERADO
                                                            EM CASO DE SINISTRO, A ÚLTIMA ALTERAÇÃO DE BENEFICIÁRIOS QUE A SEGURADORA TENHA
                                                            CONHECIMENTO.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; color:#0f243e">Este Seguro é por prazo determinado. O
                                                            registro deste plano na SUSEP não implica, por parte da Autarquia, incentivo ou
                                                            recomendação a sua comercialização. O segurado poderá consultar a situação cadastral
                                                            de seu corretor de seguros, no site www.susep.gov.br, por meio do número de seu
                                                            registro na SUSEP, nome completo, CNPJ ou CPF. As condições contratuais deste
                                                            produto protocolizadas pela ALFA junto à Susep poderão ser consultadas no endereço
                                                            eletrônico www.susep.gov.br, de acordo com o número de Processo Susep constante
                                                            neste documento e também no site:
                                                            www.alfaseguradora.com.br. O Participante e Segurado estão cientes, conforme Lei nº
                                                            12.741/12, que incide sobre o carregamento dos planos de acumulação, quando houver,
                                                            e sobre os prêmios de seguro, o PIS/PASEP com alíquota de 0,65% (zero vírgula
                                                            sessenta e cinco por cento) e a COFINS com alíquota de 4% (quatro por
                                                            cento).</span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Para sua garantia e
                                                            plena validade do presente Bilhete de Seguro, conserve-o até o final de vigência.
                                                            Confira os dados informados. Havendo qualquer divergência entre estes e aqueles
                                                            informados no momento da contratação, informe a corretora de seguros para
                                                            regularização. Direito de Arrependimento: é possível a desistência do seguro
                                                            contratado, no prazo de até 7(sete) dias corridos, contado da data de emissão do
                                                            Bilhete, com devolução integral dos valores pagos.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">DADOS DO CORRETOR:
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">CORUMBAL CORRETORA DE SEGUROS
                                                            LTDA - </span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Nº REGISTRO SUSEP:
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">10203971</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">DADOS DO
                                                            REPRESENTANTE DE SEGUROS: </span>
                                                        <span style="font-family:Arial; color:#0f243e">COMERCIAL
                                                            IVAIPORA LTDA – </span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">CNPJ: </span>
                                                        <span style="font-family:Arial; color:#0f243e">75.274.423/0001-07</span>
                                                    </p>
                                                    <p
                                                        style="margin-top:0pt; margin-bottom:0pt; text-align:justify; border-bottom-style:solid; border-bottom-width:1.5pt; padding-bottom:1pt; font-size:5pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:5pt; margin-bottom:0pt; text-align:justify; font-size:12pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">PARABÉNS PELA SUA
                                                            DECISÃO DE ADERIR AO MICROSSEGURO </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">O seu número da sorte
                                                            é: ${
																seguro.bilhete
															}</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Valor do Sorteio: R$ 10.000,00 (bruto de IR)
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">BOA SORTE!!</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt;">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:9pt">
                                        <span style="font-family:Arial">&#xa0;</span>
                                    </p>
                                    <div style="width:415pt; -aw-headerfooter-type:footer-primary;">
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial">
                                                0289-5 – Alfa Previdência e Vida S.A. – CNPJ 02.713.530/0001-02 -
                                            </span>
                                            <span style="font-family:Arial; font-weight:bold">PROCESSO SUSEP Nº 15414.902315/2014-11</span>
                                        </p>
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial; font-weight:bold">SAC 0800 774 2532 |Ouvidoria 0800 774 2352 – Uso
                                                exclusivo de deficientes auditivos SAC 0800 770 5244|Ouvidoria 0800 770 5140</span>
                                        </p>
                                    </div>
                                    <table cellspacing="0"
                                        cellpadding="0"
                                        style="width:420pt; border-collapse:collapse; margin-top:85pt;">
                                        <thead>
                                            <tr>
                                                <td
                                                    style=" border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; background-color:#0f243e">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#ffffff">BILHETE DE SEGURO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    style=" border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Ramo:
                                                            1601 – MICROSSEGUROS DE PESSOAS </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Alfa Previdência e Vida. S.A. – CNPJ </span>
                                                        <span style="font-family:Arial; color:#0f243e">02.713.530/0001-02</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Registro SUSEP:
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">0289-5</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Processo SUSEP nº
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">15414.902315/2014-11</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Representante:
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">Comercial Ivaiporã LTDA</span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e"> - CNPJ
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">75.274.423/0001-07</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Corretor:
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">Corumbal Corretora De Seguros
                                                            LTDA - </span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Código
                                                            SUSEP
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">10203971</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Declaro, como Corretor nesta contratação, que, na forma da legislação
                                                            vigente, dei cumprimento integral às disposições contidas na Resolução CNSP nº
                                                            382/2020, inclusive quanto à prévia disponibilização ao proponente das informações
                                                            previstas no art. 4º, § 1º, da referida Resolução”.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            A contratação do seguro é opcional, sendo facultado ao segurado o seu
                                                            cancelamento a qualquer tempo, com devolução do prêmio pago referente ao período a
                                                            decorrer, se houver.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            DIREITO DE ARREPENDIMENTO – é possível a desistência do
                                                            contrato em até 7 dias contado da data da contratação do seguro com devolução
                                                            integral dos valores pagos.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Em caso de extinção antecipada da obrigação, o seguro estará
                                                            automaticamente cancelado, devendo a seguradora ser formalmente comunicada, sem
                                                            prejuízo, se for o caso, da devolução do prêmio pago referente ao período a
                                                            decorrer.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Forma de Pagamento do Seguro: </span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Pagamento à vista ou fracionado junto com a compra efetuado na
                                                            Comercial Ivaiporã, Representante de Seguros, que repassará o
                                                            prêmio seguro à vista à Alfa Previdência e Vida S.A.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Custeio do Seguro: 100% Contributário, ou seja, pago integralmente pelo
                                                            cliente.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Na ocorrência de evento coberto, o
                                                            pagamento do capital segurado
                                                            ao(s) beneficiário(s) indicado(s) no Bilhete
                                                            ocorrerá de uma única vez em caso de
                                                            falecimento do segurado em decorrência de
                                                            acidente pessoal coberto e ocorrido
                                                            durante o período de vigência do microsseguro. A
                                                            indenização, quando devida, será
                                                            paga no prazo máximo de 10 (dez) dias contados a
                                                            partir da data da entrega, para a
                                                            Seguradora, de todos os documentos necessários à
                                                            comprovação ou à elucidação do
                                                            evento, atualizadas monetariamente pelo INPC
                                                            (Índice Nacional de Preços ao
                                                            Consumidor) acumulado, a partir do 1º dia útil
                                                            do mês subsequente à data do evento
                                                            até a data do efetivo pagamento.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Este seguro é por prazo determinado. O
                                                            registro deste plano na SUSEP não implica, por
                                                            parte da Autarquia, incentivo ou
                                                            recomendação a sua comercialização. O segurado
                                                            poderá consultar a situação cadastral
                                                            de seu corretor de seguros, no site
                                                            www.susep.gov.br, por meio do número de seu
                                                            registro na SUSEP, nome completo, CNPJ ou CPF.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:12pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Para contatar a seguradora: SAC 0800-774-2532, Ouvidoria
                                                            0800-774-2352 – e-mail: ouvidoria@alfaseg.com.br. Para uso exclusivo de
                                                            deficientes auditivos: SAC 0800-770-5244 | Ouvidoria: 0800-770-5140.Se
                                                            tiver dúvidas entre em contato com a CORUMBAL CORRETORA DE SEGUROS LTDA ou com a
                                                            ALFA PREVIDÊNCIA E VIDA S.A. – elas estão à sua disposição para esclarecer e
                                                            ajudar.</span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Para outras informações DISQUE SUSEP 0800-021-8484.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Este microsseguro não estará sujeito a franquia e nem a prazo de
                                                            carência, exceto no caso de suicídio ou sua tentativa, o qual corresponderá à metade
                                                            do período de vigência previsto no Bilhete</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            FRANQUIA e CARÊNCIA</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Franquia – </span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            período pelo qual o segurado fica responsável como segurador de si mesmo.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Carência – </span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            período entre a data do início da vigência do seguro e a data de entrada em vigor da
                                                            cobertura. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Sinistro – </span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            neste bilhete caracteriza-se pela morte do segurado, decorrente exclusivamente, de
                                                            acidente pessoal coberto.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">BENEFICIÁRIOS</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A qualquer momento o segurado poderá indicar beneficiários, mediante solicitação
                                                            formal, datada, assinada e protocolada junto ao representante de seguros, que
                                                            providenciará o encaminhamento para a seguradora. Não serão aceitas indicações por
                                                            meios remotos. Será considerado em caso de sinistro, a última alteração de
                                                            beneficiários que a seguradora tenha conhecimento.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            RISCOS EXCLUÍDOS</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Estão excluídos deste seguro os eventos ocorridos em consequência de:</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            a) atos ilícitos dolosos praticados pelo segurado principal ou
                                                            dependente, pelo beneficiário ou pelo representante legal, de qualquer deles;
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            b) doenças ou lesões que, apesar de indagado pela sociedade seguradora e serem de
                                                            conhecimento do segurado principal ou dependente, não foram declaradas quando da
                                                            contratação/adesão do microsseguro; </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            c) suicídio ou sequelas decorrentes da sua tentativa, caso ocorram nos
                                                            dois primeiros anos de vigência da cobertura; </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            d) epidemia ou pandemia declarada por órgão competente; </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            e) furacões, ciclones, terremotos, maremotos, erupções vulcânicas e outras
                                                            convulsões da natureza; </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            f) danos e perdas causados por atos terroristas; e </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            g) atos ou operações de guerra, declarada ou não, de guerra química ou
                                                            bacteriológica, guerra civil, guerrilha, revolução, motim, revolta, sedição,
                                                            sublevação ou outras perturbações da ordem pública e delas decorrentes, exceto a
                                                            prestação de serviço militar e atos de humanidade em auxílio de outrem.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:9pt">
                                        <span style="font-family:Arial">&#xa0;</span>
                                    </p>
                                    <div style="width:415pt; -aw-headerfooter-type:footer-primary;">
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial">
                                                0289-5 – Alfa Previdência e Vida S.A. – CNPJ 02.713.530/0001-02 -
                                            </span>
                                            <span style="font-family:Arial; font-weight:bold">PROCESSO SUSEP Nº 15414.902315/2014-11</span>
                                        </p>
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial; font-weight:bold">SAC 0800 774 2532 |Ouvidoria 0800 774 2352 – Uso
                                                exclusivo de deficientes auditivos SAC 0800 770 5244|Ouvidoria 0800 770 5140</span>
                                        </p>
                                    </div>
                                    <table cellspacing="0"
                                        cellpadding="0"
                                        style="width:420pt; border-collapse:collapse; margin-top:45pt;">
                                        <thead>
                                            <tr>
                                                <td
                                                    style=" border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; background-color:#0f243e">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#ffffff">BILHETE DE SEGURO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    style=" border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Além dos riscos excluídos previstos acima, o plano prevê
                                                            exclusões específicas conforme abaixo:</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            I – Coberturas que garantam exclusivamente eventos decorrentes de acidente
                                                            pessoal: </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            a) intercorrências ou complicações consequentes da realização de exames, tratamentos
                                                            clínicos ou cirúrgicos, quando não decorrentes de acidente pessoal coberto; </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            b) acidentes cardiovasculares, acidente vascular cerebral (AVC), aneurisma, síncope,
                                                            apoplexia, epilepsia e acidentes médicos, quando não decorrentes de acidente
                                                            coberto; </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            c) acidentes sofridos antes da contratação do microsseguro, ainda que
                                                            suas sequelas tenham se manifestado durante sua vigência; e </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            d) cirurgias plásticas ou tratamentos estéticos, exceto se tiver finalidade
                                                            comprovadamente restauradora de dano provocado por acidente pessoal coberto.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            EXCLUSÃO PARA ATOS TERRORISTAS </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Fica entendido e concordado que, não estarão cobertos danos e perdas causadas direta
                                                            ou indiretamente por ato terrorista, cabendo a sociedade seguradora comprovar com
                                                            toda documentação hábil acompanhada de Laudo Médico que caracterize a natureza do
                                                            atentado, independentemente do seu propósito, e desde que tenha sido devidamente
                                                            reconhecido como atentatório a ordem pública pela autoridade competente.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; letter-spacing:-0.3pt; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            PROCEDIMENTOS EM CASO DE SINISTRO:</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            1) AVISO DE SINISTRO</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Na ocorrência de falecimento do Segurado, decorrente exclusivamente, de acidente
                                                            pessoal coberto, ocorrido durante o período de vigência do microsseguro, seu(s)
                                                            beneficiário(s) ou representante legal deverá(ão) encaminhar
                                                            à Seguradora os documentos requeridos para a comprovação da cobertura
                                                            contratada</span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">para</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            que a mesma tome todas as providências que se façam necessárias para a liquidação do
                                                            sinistro.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A liquidação do sinistro ocorre no prazo máximo de </span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">10
                                                            (dez) dias, contados a partir do cumprimento de todas as exigências
                                                            por parte do(s) beneficiário(s) ou do seu representante legal.</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Caso sejam necessários documentos complementares, este prazo é suspenso e a
                                                            contagem do prazo voltará a correr a partir do dia útil subsequente àquele em que
                                                            forem completamente atendidas as exigências.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Na hipótese de surgimento de fatos ou circunstâncias não previstas e havendo dúvida
                                                            fundada e justificável, a seguradora reserva-se ao direito de solicitar documentos
                                                            adicionais ou cópia autenticada da documentação inicialmente encaminhada.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            2) DOCUMENTOS A SEREM ENVIADOS</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Para o caso de morte acidental, serão necessários os seguintes documentos:</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            - Formulário do Aviso de Sinistro, que deverá ser preenchido pelo(s)
                                                            beneficiário(s), pelo representante legal do segurado ou pelo corretor com os dados
                                                            do sinistro;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            - Cópia da Certidão de óbito;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            - Boletim de Ocorrência policial se for o caso; </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            - Carteira Nacional de Habilitação (CNH), na hipótese do sinistro envolver veículo
                                                            dirigido pelo segurado;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            - Cópia do RG e CPF do (a) segurado (a);</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            - Cópia do RG, CPF do (s) beneficiário (s).</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">3) ASSISTÊNCIA</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Morte – Assistência Funeral – TITUTLAR:</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Consiste no serviço ou reembolso das despesas com o funeral do(s) segurado(s),
                                                            limitado ao valor do capital segurado. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            O capital segurado está expresso no Bilhete de microsseguro contratado, observado o
                                                            limite máximo de até R$ 4.000,00 (quatro mil reais) para contratação.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            OBSERVAÇÃO IMPORTANTE:</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A Seguradora poderá solicitar outros documentos
                                                            complementares, além dos informados pela Central
                                                            de Atendimento, para esclarecimentos que julgar
                                                            necessários, em caso de dúvida
                                                            fundada e justificável. As providências ou atos
                                                            que a Seguradora praticar após
                                                            receber o aviso de sinistro não importam, por si
                                                            só, no reconhecimento da obrigação
                                                            de pagar qualquer indenização até que seja
                                                            finalizada a regulação do sinistro. A
                                                            seguradora tem prazo para efetuar o pagamento da
                                                            indenização.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            PAGAMENTO DA INDENIZAÇÃO.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            O pagamento do capital segurado ao(s) beneficiário(s) indicado(s) no Bilhete
                                                            ocorrerá de uma única vez em caso de falecimento do segurado em decorrência de
                                                            acidente pessoal coberto e ocorrido durante o período de vigência do
                                                            microsseguro.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            A indenização, quando devida, será paga no prazo máximo de 10 (dez) dias contados a
                                                            partir da data da entrega, para a Seguradora, de todos os documentos necessários à
                                                            comprovação ou à elucidação do evento, atualizadas monetariamente pelo INPC (Índice
                                                            Nacional de Preços ao Consumidor) acumulado, a partir do 1º dia útil do mês
                                                            subsequente à data do evento até a data do efetivo pagamento. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Todas as informações do seguro vinculadas a este bilhete contratado estão
                                                            disponíveis para consulta na SUSEP, </span>
                                                        <a href="http://www.susep.gov.br"
                                                            style="text-decoration:none">
                                                            <span
                                                                style="font-family:Arial; font-weight:bold; text-decoration:underline; color:#0f243e">www.susep.gov.br.</span>
                                                        </a>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Caio Valli / Celso Paiva</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Diretores - Alfa Previdência e Vida S.A.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:9pt">
                                        <span style="font-family:Arial">&#xa0;</span>
                                    </p>
                                    <div style="width:415pt; -aw-headerfooter-type:footer-primary;">
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial">
                                                0289-5 – Alfa Previdência e Vida S.A. – CNPJ 02.713.530/0001-02 -
                                            </span>
                                            <span style="font-family:Arial; font-weight:bold">PROCESSO SUSEP Nº 15414.902315/2014-11</span>
                                        </p>
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial; font-weight:bold">SAC 0800 774 2532 |Ouvidoria 0800 774 2352 – Uso
                                                exclusivo de deficientes auditivos SAC 0800 770 5244|Ouvidoria 0800 770 5140</span>
                                        </p>
                                    </div>
                                    <table cellspacing="0"
                                        cellpadding="0"
                                        style="width:420pt; border-collapse:collapse; margin-top:85pt;">
                                        <thead>
                                            <tr>
                                                <td
                                                    style=" border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; background-color:#0f243e">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#ffffff">BILHETE DE SEGURO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    style=" border-top-style:solid; border-top-width:0.75pt; border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            RESUMO DAS CONDIÇÕES GERAIS</span>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style=" border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Este Resumo contém as principais
                                                            características do produto Alfa Microsseguro de
                                                            Pessoas (Processo Susep Nº
                                                            15414.902315/2014-11), mas não substitui as
                                                            condições gerais do seguro, que poderão
                                                            ser consultadas na íntegra no site da ALFA
                                                            SEGURADORA (</span>
                                                        <a href="http://www.alfaseguradora.com.br"
                                                            style="text-decoration:none">
                                                            <span
                                                                style="font-family:Arial; text-decoration:underline; color:#0f243e">www.alfaseguradora.com.br</span>
                                                        </a>
                                                        <span style="font-family:Arial; color:#0f243e">) ou
                                                            ainda no site da SUSEP, </span>
                                                        <a href="http://www.susep.gov.br"
                                                            style="text-decoration:none">
                                                            <span
                                                                style="font-family:Arial; text-decoration:underline; color:#0f243e">www.susep.gov.br.</span>
                                                        </a>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A ALFA SEGURADORA também está disponível
                                                            para esclarecimentos em sua Central de
                                                            Atendimento no 0800-774-2532 ou no ato da
                                                            Adesão do Seguro na Comercial Ivaiporã.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            1. GLOSSÁRIO DE TERMOS TÉCNICOS</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Beneficiário:</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            É a pessoa designada para receber a indenização na hipótese de ocorrência do
                                                            sinistro coberto.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Bilhete de Seguro:
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            É o documento expedido pela Seguradora que formaliza a aceitação do
                                                            proponente no seguro.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Carência: período de tempo entre a data do início da vigência do seguro e a
                                                            data de entrada em vigor da cobertura durante o qual não há cobertura para
                                                            os sinistros ocorridos, ou seja, não haverá pagamento da indenização.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Condições Contratuais:</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            É o conjunto de disposições que regem a contratação do microsseguro, incluindo as
                                                            constantes no Bilhete, nas condições gerais e nas condições especiais.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Corretor:</span>
                                                        <span style="font-family:Arial; color:#14284b">
                                                            é a Corumbal Corretora De Seguros LTDA, devidamente habilitada e registrada junto a
                                                            SUSEP pelo nº 10.203971, que será a responsável pela intermediação, treinamento e
                                                            promoção do seguro entre o Representante e a Seguradora</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Condições Gerais:</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            É o conjunto de cláusulas que regem um mesmo plano de microsseguro, estabelecendo
                                                            obrigações e direitos da sociedade seguradora, do segurado e do(s)
                                                            beneficiário(s).</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Franquia:
                                                        </span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            período pelo qual o segurado fica responsável como segurador de si mesmo e para o
                                                            qual não há pagamento da indenização se ocorrer o sinistro coberto por este bilhete
                                                            de microsseguro. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Indenização:</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            é o valor que será pago ou reembolsado ao beneficiário se ocorrer a morte do
                                                            segurado, decorrente exclusivamente, de acidente pessoal coberto, ocorrido durante o
                                                            período de vigência do microsseguro.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Prêmio:</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            é a importância paga pelo Segurado à Seguradora para que esta assuma o risco a que o
                                                            Segurado está exposto.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Representante:</span>
                                                        <span style="font-family:Arial; color:#14284b">
                                                            é a Comercial Ivaiporã, inscrita no CNPJ 75.274.423/0001-07, pessoa jurídica que
                                                            assume a obrigação de promover em caráter não eventual e sem vínculos de
                                                            dependência, a realização de contratos de seguro à conta e em nome da sociedade
                                                            seguradora.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Risco:</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            evento futuro e incerto, de natureza súbita e imprevista, independente da vontade do
                                                            Segurado, cuja ocorrência pode provocar prejuízos de natureza econômica.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Segurado:</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            cliente que tenha sido aceito pela Seguradora e incluso no(s) Seguro(s), estando
                                                            coberto pelas cláusulas do Bilhete de Microsseguro.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">Seguradora:</span>
                                                        <span style="font-family:Arial; color:#0f243e"> é a </span>
                                                        <span style="font-family:Arial; text-decoration:underline; color:#0f243e">
                                                            Alfa Previdência e Vida S.A.</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            , CNPJ 02.713.530/0001-02, empresa autorizada pela SUSEP (Superintendência de
                                                            Seguros Privados) a funcionar no Brasil como tal.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            Sinistro: se caracteriza pela morte do segurado decorrente
                                                            exclusivamente, de acidente pessoal coberto, ocorrido durante o período de vigência
                                                            do microsseguro, exceto se decorrente de riscos excluídos, constantes no
                                                            Bilhete de Microsseguro.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            SUSEP (Superintendência de Seguros Privados): Autarquia Federal responsável pela
                                                            fiscalização, normatização e controle dos mercados de seguro, previdência
                                                            complementar aberta, capitalização, resseguro e corretagem de seguros.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            2. OBJETIVO</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Este microsseguro tem por objetivo garantir ao(s) beneficiário(s) o pagamento de
                                                            uma indenização em caso de falecimento do Segurado, decorrente exclusivamente, de
                                                            acidente pessoal coberto, quando este ocorrer dentro do período de vigência
                                                            constante no Bilhete de Microsseguro.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            3. GARANTIA DO SEGURO
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">° </span>
                                                        <span
                                                            style="font-family:Arial; font-weight:bold; letter-spacing:-0.1pt; color:#0f243e">
                                                            Morte Acidental:</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            consiste no pagamento do capital segurado ao(s) beneficiário(s) indicado(s) no
                                                            Bilhete, de uma única vez, em caso de falecimento do segurado em decorrência de
                                                            acidente pessoal coberto e ocorrido durante o período de vigência do microsseguro,
                                                        </span>
                                                        <span
                                                            style="font-family:Arial; font-weight:bold; letter-spacing:-0.1pt; color:#0f243e">
                                                            exceto se decorrente de riscos excluídos, expressamente previstos no Bilhete de
                                                            Seguro.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span
                                                            style="font-family:Arial; font-weight:bold; letter-spacing:-0.1pt; color:#0f243e">
                                                            Esta cobertura não prevê prazo de franquia e prazo de carência.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; letter-spacing:-0.1pt; color:#0f243e">
                                                            Na ocorrência de falecimento do Segurado, decorrente exclusivamente, de acidente
                                                            pessoal coberto, um familiar ou amigo </span>
                                                        <span
                                                            style="font-family:Arial; font-weight:bold; text-decoration:underline; letter-spacing:-0.1pt; color:#0f243e">
                                                            deverá comunicar a Seguradora para que a mesma tome todas as providências que se
                                                            façam necessárias</span>
                                                        <span style="font-family:Arial; letter-spacing:-0.1pt; color:#0f243e">.
                                                            As providências serão tomadas por pessoas habilitadas para esse fim.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; letter-spacing:-0.1pt; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span
                                                            style="font-family:Arial; font-weight:bold; letter-spacing:-0.1pt; color:#0f243e">
                                                            Limite de Capital</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; letter-spacing:-0.1pt; color:#0f243e">
                                                            O capital segurado será igual ao valor informado no Bilhete de
                                                            microsseguro contratado para cada Evento Coberto, observado o limite do capital
                                                            segurado para a respectiva cobertura contratada.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            4. FORMA DE CONTRATAÇÃO</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A contratação deste microsseguro será feita por intermédio de Bilhete mediante
                                                            solicitação verbal do interessado seguida da emissão do Bilhete.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            O Contrato de microsseguro prova-se com a exibição do Bilhete, na falta dele, por
                                                            documento comprobatório do pagamento do respectivo prêmio ou por confirmação de
                                                            quitação do prêmio de microsseguro enviada pela sociedade seguradora ou seu
                                                            representante com a utilização de meios remotos.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            5. INÍCIO E FIM DE VIGÊNCIA</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; letter-spacing:-0.1pt; color:#0f243e">
                                                            O prazo de vigência das coberturas oferecidas neste plano de microsseguro será de 1
                                                            (um) ano.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; letter-spacing:-0.1pt; color:#0f243e">
                                                            A vigência das coberturas oferecidas neste plano de microsseguro iniciar-se-á a
                                                            partir das 24 (vinte e quatro) horas da data do pagamento do prêmio. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span
                                                            style="font-family:Arial; font-weight:bold; letter-spacing:-0.1pt; color:#0f243e">
                                                            O término de vigência ocorrerá às 24 (vinte e quatro) horas do 365º dia corrido após
                                                            iniciada a vigência das coberturas do Bilhete. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A contratação de seguros será realizada através da adesão ao Bilhete de
                                                            Microsseguro, sendo que a manifestação poderá ser feita mediante solicitação verbal
                                                            do interessado, desde que realizada de modo
                                                        </span>
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            claro e compreensível.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Este seguro não prevê renovação.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:9pt">
                                        <span style="font-family:Arial">&#xa0;</span>
                                    </p>
                                    <div style="width:415pt; -aw-headerfooter-type:footer-primary;">
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial">
                                                0289-5 – Alfa Previdência e Vida S.A. – CNPJ 02.713.530/0001-02 -
                                            </span>
                                            <span style="font-family:Arial; font-weight:bold">PROCESSO SUSEP Nº 15414.902315/2014-11</span>
                                        </p>
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial; font-weight:bold">SAC 0800 774 2532 |Ouvidoria 0800 774 2352 – Uso
                                                exclusivo de deficientes auditivos SAC 0800 770 5244|Ouvidoria 0800 770 5140</span>
                                        </p>
                                    </div>
                                    <table cellspacing="0"
                                        cellpadding="0"
                                        style="width:420pt; border-collapse:collapse; margin-top:50pt;">
                                        <thead>
                                            <tr>
                                                <td
                                                    style=" border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; background-color:#0f243e">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#ffffff">BILHETE DE SEGURO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    style=" border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            6. PAGAMENTO DOS PRÊMIOS</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            O prêmio deste microsseguro deverá ser pago em parcela única até o dia previsto para
                                                            seu vencimento indicado no respectivo Bilhete.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            O não pagamento do prêmio até o dia previsto no respectivo Bilhete acarretará no
                                                            automático cancelamento da cobertura, observado que, se a data limite para o
                                                            pagamento do prêmio à vista coincidir com dia em que não haja expediente bancário, o
                                                            pagamento poderá ser efetuado no primeiro dia útil posterior em que houver
                                                            expediente bancário.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#14284b">
                                                            7. PROVA DO SEGURO</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; font-size:7pt">
                                                        <span style="font-family:Arial; color:#14284b">
                                                            Para cada Segurado incluído no seguro, será emitido um Bilhete de Seguro que será
                                                            entregue ao segurado, no ato da contratação, contendo todos os elementos mínimos
                                                            exigidos nos termos da legislação específica (Resolução CNSP Nº285 de 2013).</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            8. LIQUIDAÇÃO DE SINISTROS E OBRIGAÇÕES DO SEGURADO</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            No caso de ocorrência de Evento Coberto, o segurado, seu(s) beneficiário(s) ou seu
                                                            representante legal, deverá(ão) apresentar à sociedade seguradora os documentos
                                                            requeridos para a comprovação da cobertura contratada, sendo:</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span
                                                            style="font-family:Arial; font-weight:bold; text-decoration:underline; color:#0f243e">
                                                            - Cobertura de Morte Acidental:</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            a) Aviso de Sinistro;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            b) Certidão de óbito do segurado;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            c) Boletim de ocorrência policial, se for o caso;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            d) Carteira nacional de habilitação (CNH), na hipótese do Sinistro envolver veículo
                                                            dirigido pelo segurado; e</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            e) Documento de identificação do(s) beneficiário(s)</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span
                                                            style="font-family:Arial; font-weight:bold; text-decoration:underline; color:#0f243e">
                                                            - Cobertura Assistência Funeral:</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            a) Carro funerário;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            b) Coroa de flores;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            c) Ornamentação de urna;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            d) Paramentos (castiçais e velas);</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            e) Registro de óbito (registro em cartório acompanhado de um membro da
                                                            família);</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            f) Sepultamento (pagamento das respectivas taxas relacionadas ao sepultamento
                                                            nas modalidades particular ou municipal); e</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            g) Caixão (o pagamento das despesas relacionadas à aquisição da urna
                                                            funerária).</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Para efeitos de pagamento de indenização, serão aceitos como prova de identificação
                                                            do segurado e beneficiários a cédula de identidade (RG), a carteira de trabalho, a
                                                            certidão de nascimento, a certidão de casamento ou outros documentos oficiais de
                                                            identificação que possuam validade no território nacional. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A sociedade seguradora fornecerá protocolo que ateste o efetivo recebimento da
                                                            comunicação e/ou documentação comprobatória da ocorrência de Evento Coberto,
                                                            com indicação de data e hora. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            O protocolo mencionado acima poderá ser fornecido com a utilização de meios remotos
                                                            desde que seja possível validar a confirmação do recebimento. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            O prazo máximo para o pagamento da indenização é de 10 (dez) dias corridos contados
                                                            a partir da data de protocolo de entrega da documentação comprobatória, requerida
                                                            nos documentos contratuais, junto à sociedade seguradora ou representante da mesma.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A solicitação de qualquer documento comprobatório adicional por parte da sociedade
                                                            seguradora, além daqueles definidos neste item, deverá estar acompanhada de
                                                            justificativa fundamentada e ocorrer dentro do prazo máximo para pagamento da
                                                            indenização.</span>
                                                    </p>
                                                    <p style="margin-top:10pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A contagem do prazo máximo para pagamento da indenização será interrompida uma única
                                                            vez quando da solicitação de documento comprobatório adicional a que se refere o
                                                            item anterior e voltará a correr na data do seu recebimento pela sociedade
                                                            seguradora.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A solicitação de documento comprobatório adicional sem a justificativa fundamentada
                                                            não acarretará na interrupção da contagem do prazo máximo para pagamento da
                                                            indenização.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            9. PERDA DE DIREITOS</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Além dos casos previstos em lei e para este plano de microsseguro, a sociedade
                                                            seguradora ficará isenta de qualquer obrigação decorrente do microsseguro contratado
                                                            se: </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            - O segurado agravar intencionalmente o risco; </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            - O Segurado deixar de cumprir as obrigações convencionadas no plano contratado;
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            - Houver fraude ou tentativa de fraude, simulando um Sinistro ou agravando
                                                            intencionalmente as consequências de um Sinistro, para obter Indenização.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                                        <span
                                                            style="font-family:Arial; font-weight:bold; color:#0f243e; background-color:#ffff00">
                                                            REGULAMENTO DO SORTEIO</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A ALFA PREVIDÊNCIA E VIDA S.A, na qualidade de promotora do evento e proprietária
                                                            de títulos de capitalização da modalidade incentivo de contribuição única,
                                                            emitidos e administrados pela CAPEMISA Capitalização S/A, CNPJ 14.056.028/0001-55,
                                                            cujas notas técnicas e Condições Gerais foram aprovadas por meio do Processo SUSEP
                                                            nº 15414.607683/2022-50, disponível para consulta e impressão em www.susep.gov.br,
                                                            de acordo com o número acima, cederá ao Segurado, de forma gratuita e condicionada
                                                            ao preenchimento integral das condições estabelecidas neste regulamento, o direito à
                                                            participação nos sorteios vinculados aos títulos desta promoção, conferindo ao
                                                            participante um número composto por até 05 algarismos (Número da Sorte) para
                                                            concorrer aos sorteios que serão apurados com base nas extrações da Loteria
                                                            Federal.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:9pt">
                                        <span style="font-family:Arial">&#xa0;</span>
                                    </p>
                                    <div style="width:415pt; -aw-headerfooter-type:footer-primary;">
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial">
                                                0289-5 – Alfa Previdência e Vida S.A. – CNPJ 02.713.530/0001-02 -
                                            </span>
                                            <span style="font-family:Arial; font-weight:bold">PROCESSO SUSEP Nº 15414.902315/2014-11</span>
                                        </p>
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial; font-weight:bold">SAC 0800 774 2532 |Ouvidoria 0800 774 2352 – Uso
                                                exclusivo de deficientes auditivos SAC 0800 770 5244|Ouvidoria 0800 770 5140</span>
                                        </p>
                                    </div>
                                    <table cellspacing="0"
                                        cellpadding="0"
                                        style="width:420pt; border-collapse:collapse; margin-top:60pt;">
                                        <thead>
                                            <tr>
                                                <td
                                                    style=" border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; background-color:#0f243e">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#ffffff">BILHETE DE SEGURO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    style=" border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">OBJETIVO</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Ao contratar o seguro, com vigência de 12 meses, e atender às condições aqui
                                                            estabelecidas, o Segurado terá o direito de participar gratuitamente, conforme
                                                            especificado neste regulamento, disponível em
                                                            https://wwws.alfaseguradora.com.br/Portal/Afinidade/CondicoesGerais,
                                                            dos seguintes sorteios:</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            - 04 sorteios mensais, sendo 01 nos 04 últimos sábados de cada mês, a partir do mês
                                                            subsequente ao pagamento do seguro, tendo direito ao prêmio R$ 10.000,00 (dez mil
                                                            reais), sobre o qual incidirá 25% de imposto de renda, conforme o Processo SUSEP nº
                                                            15414.607683/2022-50 e legislação vigente.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            NÚMERO DA SORTE</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            O Segurado receberá o Certificado Individual do Seguro em que constará, dentre
                                                            outras informações, o Número da Sorte com o qual concorrerá ao sorteio, a quantidade
                                                            de sorteios que participará e a data de início de participação. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">SORTEIO</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A participação do Segurado iniciará a partir do mês subsequente ao pagamento do
                                                            seguro, sendo sua participação assegurada enquanto o seguro estiver vigente, o
                                                            pagamento do prêmio do seguro rigorosamente em
                                                            dia e a promoção vigente.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Os sorteios serão apurados com base no resultado das extrações da Loteria Federal do
                                                            Brasil realizadas aos sábados. O resultado da Loteria Federal poderá ser
                                                            acompanhado por meio do site
                                                            http://www1.caixa.gov.br/loterias/loterias/federal/federal_resultado.asp
                                                            e em qualquer Casa Lotérica.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Não ocorrendo extração da Loteria Federal na data prevista, o sorteio correspondente
                                                            será adiado para a primeira extração que vier a ser realizada até o dia que
                                                            anteceder o sábado seguinte.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Para efeito de apuração, será contemplado o título vigente na data do sorteio, cujo
                                                            NÚMERO DA SORTE, informado no Certificado Individual do Seguro, coincida, observada
                                                            a ordem de premiação, com a leitura da coluna formada pelos algarismos da unidade
                                                            simples dos 05 primeiros prêmios extraídos pela Loteria Federal, lidos de cima
                                                            para baixo, conforme o exemplo abaixo:</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            1º Prêmio 56.892 </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            2º Prêmio 34.873</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            3º Prêmio 66.834</span>
                                                        <span style="display:inline-block">&#xa0;</span>
                                                        <span style="display:inline-block">&#xa0;</span>
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Número Contemplado: 23.451</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            4º Prêmio 07.605</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            5º Prêmio 70.521</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            No caso da combinação para sorteio, apurada na forma acima, não tenha sido
                                                            distribuída a um Segurado desta promoção ou esteja vinculada a um Segurado que não
                                                            atenda às condições previstas neste regulamento, este será desclassificado e não
                                                            terá direito ao prêmio.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">PREMIAÇÃO</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A combinação contemplada e o nome do contemplado, abreviado em respeito à LGPD,
                                                            serão divulgados em https://wwws.alfaseguradora.com.br/Portal/ResultadoSorteio.
                                                            O contemplado será avisado por meio de e-mail, telefone ou carta e
                                                            só terá direito à premiação se o seguro estiver vigente, o pagamento do prêmio do
                                                            seguro rigorosamente em dia e a promoção vigente.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:2pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A premiação será paga pela CAPEMISA Capitalização S/A no prazo máximo de 15 dias
                                                            corridos, contado da apresentação das informações necessárias ao pagamento da
                                                            premiação: a) nome completo, número do CPF/MF, endereço residencial completo,
                                                            telefone com DDD e dados bancários de sua titularidade para pagamento por meio de
                                                            depósito bancário; b) se necessário, poderão ser solicitadas outras informações ou
                                                            documentos na forma e cumprimento dos normativos da SUSEP. </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A participação na promoção implica automática adesão aos termos deste regulamento,
                                                            bem como na autorização do compartilhamento dos dados pessoais do contemplado com a
                                                            CAPEMISA Capitalização S/A, especificamente para o pagamento da premiação,
                                                            que ficarão à disposição dos órgãos de fiscalização (SUSEP/Receita Federal) pelo
                                                            prazo legal.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Os dados pessoais colhidos serão utilizados única e exclusivamente no âmbito da
                                                            promoção em conformidade e em respeito à Lei nº 13.709/2018 (“LGPD - Lei Geral
                                                            de Proteção de Dados Pessoais”).</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Na eventualidade do contemplado falecer o prêmio será entregue ao espólio, na pessoa
                                                            do seu inventariante e mediante apresentação do Termo de Inventariança. Não havendo
                                                            processo de inventário, será entregue aos sucessores legais mediante apresentação de
                                                            alvará judicial, nos termos da legislação aplicável.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A promotora obriga-se a identificar todos os participantes cessionários dos direitos
                                                            dos eventuais títulos integralmente cedidos, bem como os participantes ganhadores
                                                            dos prêmios de sorteios.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Antes de contratar consulte previamente as Condições Gerais disponíveis em
                                                            www.susep.gov.br.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:9pt">
                                        <span style="font-family:Arial">&#xa0;</span>
                                    </p>
                                    <div style="width:415pt; -aw-headerfooter-type:footer-primary;">
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial">
                                                0289-5 – Alfa Previdência e Vida S.A. – CNPJ 02.713.530/0001-02 -
                                            </span>
                                            <span style="font-family:Arial; font-weight:bold">PROCESSO SUSEP Nº 15414.902315/2014-11</span>
                                        </p>
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial; font-weight:bold">SAC 0800 774 2532 |Ouvidoria 0800 774 2352 – Uso
                                                exclusivo de deficientes auditivos SAC 0800 770 5244|Ouvidoria 0800 770 5140</span>
                                        </p>
                                    </div>
                                    <table cellspacing="0"
                                        cellpadding="0"
                                        style="width:420pt; border-collapse:collapse; margin-top:80pt;">
                                        <thead>
                                            <tr>
                                                <td
                                                    style=" border-right-style:solid; border-right-width:0.75pt; border-left-style:solid; border-left-width:0.75pt; border-bottom-style:solid; border-bottom-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top; background-color:#0f243e">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:10pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#ffffff">BILHETE DE SEGURO</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    style=" border-style:solid; border-width:0.75pt; padding-right:5.03pt; padding-left:5.03pt; vertical-align:top">
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            Em razão da adesão aos termos da promoção, o contemplado poderá consentir de
                                                            forma gratuita o direito de uso do seu nome, imagem e voz pelo período de um ano,
                                                            contado a partir do sorteio, especificamente para divulgação da promoção, em
                                                            qualquer tipo de mídia, a critério da promotora, sem restrição de frequência e sem
                                                            que isso implique qualquer tipo de ônus.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            O prazo para reclamação do direito ao prêmio pelo contemplado é aquele estabelecido
                                                            pela legislação em vigor. O valor não exigido dentro do prazo prescricional
                                                            acarretará a perda desse direito.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            É proibida a venda de título de capitalização a menores de dezesseis anos.
                                                        </span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A promoção poderá ser encerrada a qualquer momento mediante comunicado com 30 dias
                                                            de antecedência em https://wwws.alfaseguradora.com.br, conforme Circular SUSEP
                                                            656/2022.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                                        <span style="font-family:Arial; font-weight:bold; color:#0f243e">
                                                            ABRANGÊNCIA E PERÍODO DA PROMOÇÃO</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A Promoção Comercial tem abrangência nacional e início em 25/05/2022, vigorando por
                                                            prazo indeterminado.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">
                                                            A Ouvidoria da CAPEMISA Capitalização S/A estará à disposição, gratuitamente, pelo
                                                            telefone 0800-707-4936, de segunda a sexta-feira, das 8h às 17h, como instância de
                                                            decisão superior, após o atendimento pelos outros canais disponíveis no site
                                                            http://www.capemisa.com.br/a-capemisa/capemisa-capitalizacao/,
                                                            inclusive pelo SAC 0800-940-1130, 24h por dia, sete dias por semana.</span>
                                                    </p>
                                                    <p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; font-size:7pt">
                                                        <span style="font-family:Arial; color:#0f243e">&#xa0;</span>
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p style="margin-top:0pt; margin-bottom:0pt; line-height:115%; font-size:9pt">
                                        <span style="font-family:Arial">&#xa0;</span>
                                    </p>
                                    <div style="width:415pt; -aw-headerfooter-type:footer-primary;">
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial">
                                                0289-5 – Alfa Previdência e Vida S.A. – CNPJ 02.713.530/0001-02 -
                                            </span>
                                            <span style="font-family:Arial; font-weight:bold">PROCESSO SUSEP Nº 15414.902315/2014-11</span>
                                        </p>
                                        <p style="margin-top:0pt; margin-bottom:0pt; text-align:center; font-size:7pt">
                                            <span style="font-family:Arial; font-weight:bold">SAC 0800 774 2532 |Ouvidoria 0800 774 2352 – Uso
                                                exclusivo de deficientes auditivos SAC 0800 770 5244|Ouvidoria 0800 770 5140</span>
                                        </p>
                                    </div>
                                </div>
                            </body>
                        </html>`;
			let doc = new jsPDF("p", "pt", "a4");
			let dataSeguro = new Date();
			doc.html(htmlContent, {
				callback: function (pdf) {
					pdf.save(
						`seguro_${dataSeguro.toISOString().split(".")[0]}.pdf`
					);
				},
			});
			doc.autoPrint();
			this.carregando = false;
		},
		mes(mes) {
			mes = parseInt(mes);
			switch (mes) {
				case 1:
					return "Janeiro";
				case 2:
					return "Fevereiro";
				case 3:
					return "Março";
				case 4:
					return "Abril";
				case 5:
					return "Maio";
				case 6:
					return "Junho";
				case 7:
					return "Julho";
				case 8:
					return "Agosto";
				case 9:
					return "Setembro";
				case 10:
					return "Outubro";
				case 11:
					return "Novembro";
				default:
					return "Dezembro";
			}
		},
		async init() {
			this.busca.idfilial = this.usuario.idfilial;
			await this.listar(0);
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>